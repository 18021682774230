exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-edi-docs-edi-docs-template-tsx": () => import("./../../../src/templates/edi-docs/EDIDocsTemplate.tsx" /* webpackChunkName: "component---src-templates-edi-docs-edi-docs-template-tsx" */),
  "component---src-templates-faq-faq-template-tsx": () => import("./../../../src/templates/faq/FaqTemplate.tsx" /* webpackChunkName: "component---src-templates-faq-faq-template-tsx" */),
  "component---src-templates-tutorial-tutorial-template-tsx": () => import("./../../../src/templates/tutorial/TutorialTemplate.tsx" /* webpackChunkName: "component---src-templates-tutorial-tutorial-template-tsx" */),
  "component---src-templates-whats-new-whats-new-template-tsx": () => import("./../../../src/templates/whats-new/WhatsNewTemplate.tsx" /* webpackChunkName: "component---src-templates-whats-new-whats-new-template-tsx" */)
}

